<script>
import { phoneRegex } from '@/core'
import Icon from '@/UI/icon/Icon'

export default {
  name: 'TableCell',
  components: { Icon },
  props: {
    item: {
      type: [String, Array, Number, Boolean],
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
    propKey: {
      type: String,
      default: '',
    },
    status: {
      type: [String, Number, Boolean],
      default: '',
    },
  },
  methods: {
    createCellAllOption(createElement) {
      const { name, inn } = this.row
      const prepareName = createElement('div', name)
      const content = [prepareName, inn]

      return content.map((item, i) => {
        if (item) {
          return createElement('div', { class: [`allOption${i}`] }, [item])
        }
      })
    },
    createCellPhones(createElement) {
      const { phones } = this.row

      return phones.map((item, i) => {
        if (item.phone) {
          return createElement('div', { class: [`phone${i}`] }, [
            phoneRegex(item.phone),
          ])
        }
      })
    },
    createCellFio(createElement) {
      const { phones } = this.row

      return phones.map((item, i) =>
        createElement('div', { class: [`phone${i}`] }, [
          item.user_name || 'Не указан',
        ]),
      )
    },
  },
  render(createElement) {
    let classNames = [this.propKey]
    let renderData = [this.item]

    if (Array.isArray(this.item)) {
      renderData = this.item.map(item => createElement('div', { ...item }))
    }

    switch (this.propKey) {
      case 'allOption':
        renderData = this.createCellAllOption(createElement)
        classNames.push('ai-fs')
        break

      case 'phones':
        renderData = this.createCellPhones(createElement)
        break

      case 'fio':
        renderData = this.createCellFio(createElement)
    }

    return createElement('div', { class: classNames }, renderData)
  },
}
</script>
